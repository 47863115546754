import IService from './iservice';
import config from "@/config";
import store from "../store/store";

const server_base = config[config.stage].ws_server;

class LegalDocumentService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async fillPDF(pdfName, filters = {}) {
    try {
      const query = {pdfName};

      query.customerId = filters?.customerId || '';
      query.carVin = filters?.carVin || '';

      const response = await this.http.get(`${server_base}/api/legal-document/fill-pdf`, query);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip, filters = {}) {
    try {
      const query = {};

      if (limit) query.limit = limit;
      if (skip) query.skip = skip;
      if (filters.sections) query.sections = filters.sections
      if (filters.company) query.company = filters.company
      if (filters.search) query.search = filters.search

      this.addLoading('list-legal-documents');
      const response = await this.http.get(`${server_base}/api/legal-document/`, query);
      this.delLoading('list-legal-documents');
      return response.data;
    } catch (e) {
      this.delLoading('list-legal-documents');
      return null;
    }
  }

  async delete(id) {
    try {
      const response = await this.http.delete(`${server_base}/api/legal-document/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default LegalDocumentService;