import DataService from '@/services/data.service';
import CompanyService from "@/services/company.service";

const dataService = new DataService();
const companyService = new CompanyService();

export default {
  name: 'MatchLegalDocument',

  props: {
    onClose: Function,
    onUpdated: Function,
    document: Object,
  },

  data() {
    return {
      isLoading: false,

      fieldsToMap: [],
      formFields: [],

      companySelected: null,
      companies: [],
      sectionsSelected: [],
      sections: [
        {value: 'appraisal', text: this.$t('appraisal')},
        {value: 'customer', text: this.$t('customer')},
      ],

      searchQuery: ''
    }
  },

  async mounted() {
    await Promise.all([
      this.getFieldsToMap(),
      this.listCompanies()
    ]);

    this.companySelected = this.document?.Company?._id || null;
    this.sectionsSelected = this.document?.showInSections || [];
    this.formFields = this.document?.formFields;
  },

  computed: {
    dbFieldsToMatch() {
      if (this.searchQuery) {
        return this.fieldsToMap.filter(item => (
            this.searchQuery.toLowerCase().split(' ')
                .every(el => item.localFieldAlias.toLowerCase().includes(el))
        ));
      }

      return this.fieldsToMap;
    },
  },

  methods: {
    async getFieldsToMap() {
      const res = await dataService.getFieldsToMap();
      if (res && !res.error) {
        this.fieldsToMap = res.data;
      }
    },

    async onMap() {
      this.isLoading = true;

      const _document = {...this.document};

      _document.Company = this.companySelected;
      _document.showInSections = this.sectionsSelected;
      _document.formFields = this.formFields;
      _document.isMapped = true;

      const res = await dataService.mapLegalDocument(_document);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('map_success')});
      }

      this.closeModal();
    },

    async listCompanies() {
      const res = await companyService.list();
      if (res && !res.error) {
        this.companies = res.data.companies;
      }
    },

    selectField(fieldToMatch, formField) {
      this.$hideModals();
      this.search = '';

      formField['localField'] = fieldToMatch.localField;
      formField['localFieldAlias'] = fieldToMatch.localFieldAlias;
      formField['modelRelated'] = fieldToMatch.modelRelated;
    },

    confirmField(formField) {
      formField.isMapped = true;
      formField.isIgnored = false;
    },

    ignoreField(formField) {
      formField.isMapped = true;
      formField.isIgnored = true;
    },

    closeModal() {
      this.isLoading = false;
      if (this.onClose) this.onClose();
    },
  },
}