import {bus} from "@/helpers/bus";
import {mapActions} from "vuex";
import Dh from "@/helpers/date.helper";

import MapModal from '../MapModal';

import LegalDocumentService from '@/services/legal.document.service';
const legalDocumentService = new LegalDocumentService();

export default {
  name: 'list',

  data() {
    return {
      documents: [],
      document: null,

      fieldsToMap: [],

      removeModal: false,
      mapModal: false,
      isLoading: false,

      searchTerm: '',
      perPage: 25,
      totalRows: 0,
      currentPage: 1,
    }
  },

  components: {
    MapModal
  },

  async mounted() {
    await this.list();
  },

  methods: {
    ...mapActions([
      'setUploadImportFileStep',
      'setUploadImportFileData'
    ]),

    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const filters = {
        search: this.searchTerm,
      }

      const res = await legalDocumentService.list(this.perPage, skip, filters);
      if (res && !res.error) {
        this.documents = res.data.documents;
        this.totalRows = res.data.count;
      }
    },

    async remove() {
      this.isLoading = true;

      const res = await legalDocumentService.delete(this.document._id);
      if (res && !res.error) {

        const index = this.documents.indexOf(this.document)
        if (index > -1) {
          this.documents.splice(index, 1);
        }

        this.$notify({message: this.$t('delete_success'), type: 'success'});

      } else {

        this.$notify({message: this.$t('delete_error'), type: 'error'});
      }

      this.closeModal();
    },

    async map() {
      this.isLoading = true;

      this.setUploadImportFileStep('Match');
      await this.setUploadImportFileData({legalDocument: this.document});
      await this.$router.push({name: 'ImportLegalDocumentMatch'});


      this.closeModal();
    },

    async importFile() {
      await this.$router.push({name: 'ImportLegalDocumentUpload'});
    },

    openRemove(document) {
      this.document = document;
      this.removeModal = true;
    },

    async openMap(document) {
      this.document = document;
      this.mapModal = true;
    },

    closeModal() {
      this.document = null;
      this.removeModal = false;
      this.mapModal = false;
      this.isLoading = false;
    },

    async setPerPage(num) {
      this.perPage = +(num);
      bus.$emit('HIDE_ALL_MODALS');
      await this.list();
    },

    async next() {
      await this.list(this.currentPage + 1);
    },

    async prev() {
      await this.list(this.currentPage - 1);
    },
  },

  filters: {
    mappedFields(fields = []) {
      return fields.filter(el => el.isMapped && !el.isIgnored).length;
    },

    ignoredFields(fields = []) {
      return fields.filter(el => el.isMapped && el.isIgnored).length;
    },

    createdAt(date) {
      return Dh.parseDate(date);
    },
  },

  watch: {
    async searchTerm() {
      await this.list();
    },
  },
}